body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input {
    font-size: 18px;
    padding: 8px;
    border-radius: 8px;
    border: 2px solid dodgerblue;
}

button {
    background: dodgerblue;
    color: white;
    padding: 16px 32px;
    border-radius: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
}
